import anime from "animejs/lib/anime.es.js";

const ReadmoreBtn = document.querySelector(".ReadmoreToggler");
const Information = document.querySelector(".Information__Content");

anime({
    targets: Information,
    height: 250,
    easing: "easeInOutSine"
});

var animation = anime({
    targets: ".Information__Content",
    height: ["100%"],
    easing: "easeInOutSine",
    autoplay: false
});
document.querySelector(".ReadmoreToggler").onclick = animation.play;
